import { MatterType } from '@/helpers/types';

export function showImg(matter: MatterType): boolean {
  return (
    !!matter.image &&
    ['high', 'main', 'special_project'].includes(matter.priority)
  );
}

export function showLead(matter: MatterType): boolean {
  return ['normal', 'high', 'main', 'special_project'].includes(
    matter.priority,
  );
}

export function isSpecialProject(matter: MatterType): boolean {
  return matter.priority === 'special_project';
}

export function isTildaProject(matter: MatterType): boolean {
  return Boolean(matter.tilda_content) && Boolean(matter.tilda_url);
}

// export function bigAdwertismentWrapper = () =>{}
