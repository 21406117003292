import { useEffect } from 'react';

import { useToplineReducer } from '@/components/Topline/toplineContext';

import { ImageType } from './types';

export default function useToplineTransparenty(
  image?: ImageType | boolean,
): void {
  const [, dispatch] = useToplineReducer();

  useEffect(() => {
    if (image) {
      dispatch({ type: 'SET_TRANSPARENT', payload: true });
    } else {
      dispatch({ type: 'SET_TRANSPARENT', payload: false });
    }
  }, []);
}
