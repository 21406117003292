import api from '@/helpers/api';
import { Ecommerce, PlatformType } from '@/helpers/types';

declare global {
  interface Window {
    ym: (
      id: string,
      command: string,
      url: string | { [key: string]: boolean | string },
      params?: { [key: string]: unknown } | string,
    ) => void;
    ga: (id: string, command: string, url: string, num?: number) => void;
    dataLayer?: {
      push: (ecommerce: Ecommerce) => void;
    };
  }
}

/* eslint-disable */
export function insertYmScript(): void {
  // ym
  (function (m, e, t, r, i, k, a) {
    // @ts-ignore
    m[i] =
      // @ts-ignore
      m[i] ||
      function () {
        // @ts-ignore
        (m[i].a = m[i].a || []).push(arguments);
      };
    // @ts-ignore
    m[i].l = 1 * new Date();
    // @ts-ignore
    (k = e.createElement(t)),
      // @ts-ignore
      (a = e.getElementsByTagName(t)[0]),
      // @ts-ignore
      (k.async = 1),
      // @ts-ignore
      (k.src = r),
      // @ts-ignore
      a.parentNode.insertBefore(k, a);
  })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
}

// google tag manager
export function inserGtmScript(googleTagManagerId: string) {
  const head = document.querySelector('head');
  if (head && googleTagManagerId) {
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagManagerId}`;
    head.appendChild(script1);

    // @ts-expect-error @TODO fix types
    function script2() {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.gtag = function gtag() {
        // @ts-ignore
        window.dataLayer.push(arguments);
      };
      // @ts-ignore
      gtag('js', new Date());
      // @ts-ignore
      gtag('config', googleTagManagerId);
    }

    script2();
  }
}

export function insertTopMailScript(topMailId: string) {
  //@ts-ignore
  const _tmr = window._tmr || (window._tmr = []);
  _tmr.push({ id: topMailId, type: 'pageView', start: new Date().getTime() });
  (function (d, w, id) {
    if (d.getElementById(id)) return;
    var ts = d.createElement('script');
    ts.type = 'text/javascript';
    ts.async = true;
    ts.id = id;
    ts.src = 'https://top-fwz1.mail.ru/js/code.js';
    const f = function () {
      const s = d.getElementsByTagName('script')[0];
      s.parentNode?.insertBefore(ts, s);
    };
    // @ts-ignore
    if (w.opera == '[object Opera]') {
      d.addEventListener('DOMContentLoaded', f, false);
    } else {
      f();
    }
  })(document, window, 'tmr-code');
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `<noscript><div><img src="https://top-fwz1.mail.ru/counter?id=${topMailId};js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" /></div></noscript>`;
  document.head.appendChild(noscript);
}

//ga
export function insertGaScript(): void {
  (function (i, s, o, g, r, a, m) {
    // @ts-ignore
    i['GoogleAnalyticsObject'] = r;
    // @ts-ignore
    (i[r] =
      // @ts-ignore
      i[r] ||
      function () {
        // @ts-ignore
        (i[r].q = i[r].q || []).push(arguments);
      }),
      // @ts-ignore
      (i[r].l = 1 * new Date());
    // @ts-ignore
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    // @ts-ignore
    a.async = 1;
    // @ts-ignore
    a.src = g;
    // @ts-ignore
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    'https://www.google-analytics.com/analytics.js',
    'ga',
  );
}

export const initYm = (yandex_metrika_id: string): void => {
  // Тесты для oilcapital.ru и tagilcity.ru
  if (
    typeof window !== 'undefined' &&
    (window.location.host.includes('oilcapital.ru') ||
      window.location.host.includes('tagilcity.ru') ||
      window.location.host.includes('localhost'))
  ) {
    window.ym(yandex_metrika_id, 'init', {
      defer: true,
      clickmap: false, //true
      sendTitle: false, //true
      trackLinks: false, //true
      accurateTrackBounce: true,
      webvisor: false, //true
      ecommerce: 'dataLayer',
    });

    return;
  }

  window.ym(yandex_metrika_id, 'init', {
    defer: true,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    ecommerce: 'dataLayer',
  });
};

export const initGa = (
  google_analytics_id: string,
  platformId: number,
): void => {
  window.ga('create', google_analytics_id, 'auto', platformId);
};

function getLiUrl(url: string, id: string): string {
  return (
    `https://counter.yadro.ru/hit;${id || ''}?r` +
    escape(document.referrer) +
    (typeof screen === 'undefined'
      ? ''
      : ';s' +
        screen.width +
        '*' +
        screen.height +
        '*' +
        (screen.colorDepth ? screen.colorDepth : screen.pixelDepth)) +
    ';u' +
    escape(url) +
    ';h' +
    escape(document.title.substring(0, 150)) +
    ';' +
    Math.random()
  );
}
function hitLi(url: string, id: string): void {
  new Image().src = getLiUrl(url, id);
}

function hitGlobalLi(url: string): void {
  const urlLi = getLiUrl(url, '');
  const data = {
    url: urlLi,
    domain: document.referrer,
  };

  // post to /api/site/liveinternet_hit, {urlLi, domain}
  api.post('liveinternet_hit', data);
}

export function hitCounters(
  platform: Pick<PlatformType, 'analytics'> & { external_url?: string },
): void {
  const url = platform.external_url
    ? `${platform.external_url}?utm_source=skad`
    : document.URL;
  if (platform.analytics.top_mail_counter) {
    //@ts-ignore
    window._tmr?.push({
      id: platform.analytics.top_mail_counter,
      type: 'pageView',
      url,
    });
  }
  window.ym(platform.analytics.yandex_metrika_id, 'hit', url);
  if (platform.analytics.yandex_metrika_id_2)
    window.ym(platform.analytics.yandex_metrika_id_2, 'hit', url);
  if (platform.analytics.yandex_metrika_id_3)
    window.ym(platform.analytics.yandex_metrika_id_3, 'hit', url);
  window.ga('send', 'pageview', url);
  // https://git.dev.1mi.media/1mi/v2/front_site/-/issues/32
  hitLi(url, '');
  if (platform.analytics.liveinternet_counter_id) {
    hitLi(url, platform.analytics.liveinternet_counter_id);
  }
  if (platform.analytics.liveinternet_counter_id_2) {
    hitLi(url, platform.analytics.liveinternet_counter_id_2);
  }
  if (platform.analytics.liveinternet_counter_id_3) {
    hitLi(url, platform.analytics.liveinternet_counter_id_3);
  }

  if (platform.external_url) {
    hitGlobalLi(platform.external_url);
  }
}

export function reachGoal(
  platform: PlatformType,
  goal: string,
  value?: string | { [key: string]: unknown },
): void {
  function callYandexMetrika(id: string | undefined) {
    if (id) {
      if (value !== undefined) {
        window.ym(id, 'reachGoal', goal, value);
      } else {
        window.ym(id, 'reachGoal', goal);
      }
    }
  }
  callYandexMetrika(platform.analytics.yandex_metrika_id);
  callYandexMetrika(platform.analytics.yandex_metrika_id_2);
  callYandexMetrika(platform.analytics.yandex_metrika_id_3);
}

export const insertGTMScriptByKey = (key: string | null): void => {
  if (!key) return;

  if (key.includes('G-')) {
    (function (d, s, o, g, r, a, m) {
      r = key;
      // @ts-ignore
      (a = d.createElement(o)), (m = d.getElementsByTagName(o)[0]);
      // @ts-ignore
      a.async = 1;
      // @ts-ignore
      a.src = g + r;
      // @ts-ignore
      m.parentNode.insertBefore(a, m);
      // @ts-ignore
      a.onload = function () {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          // @ts-ignore
          dataLayer.push(arguments);
        }
        // @ts-ignore
        gtag('js', new Date());
        // @ts-ignore
        gtag('config', r);
      };
    })(
      document,
      'script',
      'script',
      'https://www.googletagmanager.com/gtag/js?id=',
      key,
    );
  } else if (key.includes('GTM-')) {
    (function (w, d, s, l, i) {
      // @ts-ignore
      w[l] = w[l] || [];
      // @ts-ignore
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      // @ts-ignore
      j.async = true;
      // @ts-ignore
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      // @ts-ignore
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', key);

    // No-script part for GTM
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${key}"
                            height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);
  }
};
