import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import styles from '@/styles/components/EditButton/index.module.scss';

const EditButton: React.FC<{ url: string }> = ({ url }): React.ReactElement => {
  const [visible, setVisible] = useState(false);
  const [debugVisible, setDebugVisible] = useState(false);

  useEffect(() => {
    setVisible(!!localStorage.getItem('token'));
  }, []);

  useEffect(() => {
    setDebugVisible(!!localStorage.getItem('front_debug'));
  }, []);

  const debugAdminUrl =
    'http://localhost:4000' + url.replace('http://localhost:3001', '');

  return visible ? (
    <>
      <a href={url} target="_blank" rel="noreferrer" title="Редактировать">
        <span className={styles.button}></span>
      </a>

      {debugVisible && (
        <>
          <a
            href={debugAdminUrl}
            target="_blank"
            rel="noreferrer"
            title="Редактировать на локалке"
          >
            <span
              className={classNames(styles.button, 'opacity-50 !bottom-[76px]')}
            ></span>
          </a>
          {location.origin !== 'http://localhost:3001' && (
            <a
              href={`http://localhost:3001${location.pathname}`}
              target="_blank"
              rel="noreferrer"
              title="Смотреть на локалке"
            >
              <span
                className={classNames(
                  styles.button,
                  'opacity-30 !bottom-[136px]',
                )}
              ></span>
            </a>
          )}
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default EditButton;
